<template>
  <notifications
    :width="isMobile ? '100%' : '320px'"
    :position="isMobile ? 'top right' : 'bottom right'"
    :class="!isMobile ? 'mb-4 mr-6' : 'pt-2 px-2'"
  >
    <template #body="{ item, close }">
      <div
        class="notification my-1 px-3 py-6 lg:py-4 lg:my-2 text-white rounded-md bg-opacity-95"
        :class="themes[item.type]"
      >
        <div class="flex justify-between items-center space-x-3">
          <span class="mr-auto leading-tight text-sm font-bold">
            {{ $t(item.text) }}
          </span>
          <button
            @click="close"
            class="focus:outline-none inline-flex flex-shrink-0"
          >
            <img
              src="@/assets/images/base/icons/close-circle-white.svg"
              width="28"
              height="28"
              loading="lazy"
              alt=""
            />
          </button>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
import isMobile from "@/mixins/isMobile.js";

export default {
  name: "LayoutsNotification",
  mixins: [isMobile],
  data() {
    return {
      themes: {
        info: "bg-primary",
        success: "bg-success",
        danger: "bg-error",
      },
    };
  },
};
</script>
