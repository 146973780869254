<template>
  <div class="relative rounded-lg shadow-md" :class="themes[theme]">
    <slot />
  </div>
</template>

<script>
export default {
  name: "CoreBoxWrapper",
  props: {
    theme: {
      type: String,
      required: false,
      default: "white",
    },
  },
  data() {
    return {
      themes: {
        blured: "bg-background bg-opacity-90",
        bluredWhite: "bg-white bg-opacity-90",
        white: "bg-white",
      },
    };
  },
};
</script>
